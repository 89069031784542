class ComunidadPage extends Component {

    static  name() {
        return "ComunidadPage";
    }

    static componentName() {
        return "ComunidadPage";
    }

    getTemplate() {
        return `<div class="static-page">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="section-content container">
                        <div class="section-header text-center">
                            <img src="/static/ecommerce/img/comunidad_banner.jpeg" class="img-fluid" alt="" />
                        </div>
                        <div class="row mt-5">
                            <div class="col">
                                <div>
                                    <p>Ofisnack es la tienda ideal para aquellas personas que se preocupan por tener una buena alimentación, para los que buscan la mayor variedad de 
                                    snacks del mercado a muy buen precio y exigen los mejores niveles de servicio y calidad. Este concepto de tienda esta pensado tanto para las personas 
                                    individuales como así también para las familias. Ofisnack es el snack que tu hijo se lleva al colegio, el que te alivia un break o te da el gusto de 
                                    algo rico pero que a su vez es saludable, evitando de esta manera comer productos artificiales y poco saludables. Ofisnack es para los que priorizan 
                                    su salud, bienestar y futuro, y hacen de ello un estilo de vida.</p>

                                    <p class="big-quote big-quote-green my-2">
                                        <span><img src="/static/ecommerce/img/cuote-open-blue.png" width="38" class="img-fluid" alt="" /></span>
                                        Ofisnack es el snack que tu hijo se lleva al colegio, el que te alivia un break o te da el gusto de algo rico pero que a su vez es saludable
                                        <span><img src="/static/ecommerce/img/cuote-close-blue.png" width="38" class="img-fluid" alt="" /></span>
                                    </p>

                                    <p>En nuestro sitio web vas a encontrar todo el surtido de snacks saludables y naturales que existen en el mercado. Nuestro equipo de expertos selecciona 
                                    minuciosamente a cada uno de los fabricantes, de acuerdo con las consignas planteadas. En ese sentido, priorizamos que los productores sean personas 
                                    apasionadas por lo que hacen. Que busquen innovar a través de la calidad de sus productos, que tengan la conciencia de fabricar un producto honesto que 
                                    le de valor agregado al mercado. Que tengan el sueño y la determinación de vivir en un mundo mejor, ayudando al medio ambiente y a la comunidad en la cual 
                                    se desenvuelven.</p>
                                </div>
                            </div>
                            <div class="col-12 col-md-3 text-right">
                                <img src="/static/ecommerce/img/comunidad_img_lateral.jpeg" class="img-fluid mb-3" alt="" />
                                <img src="/static/ecommerce/img/comunidad_img_lateral2.jpeg" class="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

ComunidadPage.registerComponent();